.donation-inner-container {
  width: 100%;
	height: auto;
  position: relative;
	font-size: 20px;
  padding-bottom: 2%;
  background-image: url("/public/Images/home/sky-stars.png");
	background-repeat: no-repeat;
	background-position: center top -9.8%;
	background-size: 100%;
}

@media screen and (max-width: 1400px) {
  .donation-inner-container {
    background-image: url("/public/Images/home/sky-stars.png");
    background-position: center top -6.7%;
  }
}

@media screen and (max-width: 430px) {
  .donation-inner-container {
    background-image: url("/public/Images/home/sky-stars.png");
    background-position: center top -0.7%;
  }
}

@media screen and (max-width: 340px) {
  .donation-inner-container {
    background-image: url("/public/Images/home/sky-stars.png");
    background-position: center top -0.8%;
  }
}

#pointing_ziggy {
  left: -6%;
  top: 18%;
  width: 12%;
}

#donation_ziggy {
  right: -34%;
  top: -11%;
  width: 30%;
}

#donation_thinking_ziggy {
  left: -33%;
  top: -3%;
  width: 20%;
}

#donation_box_holding_ziggy {
  right: -31%;
  top: -2%;
  width: 24%;
}

#no_coin {
  left: -31%;
  top: 27%;
  width: 20%;
}

#coin_looking_ziggy {
  right: -50%;
  top: 51%;
  width: 33%;
}

#safeguarding_ziggy {
  left: -50%;
  top: -5%;
  width: 32%;
}

#safeguarding_footprints {
  right: -50%;
  top: 62%;
  width: 32%;
}

#gift_ziggy {
  left: -35%;
  top: -27%;
  width: 60%;
}

#staircase_ziggy {
  right: -38%;
  top: -54%;
  width: 35%;
}

#person_lock {
  right: -26%;
  bottom: 77%;
  width: 27%;
}

#donation_data_safe_ziggy {
  left: -30%;
  bottom: 54%;
  width: 28%;
}

#calender_ziggy {
  right: -50%;
  bottom: 25%;
  width: 30%;
}

#coin_slotting_ziggy {
  left: -50%;
  bottom: -33%;
  width: 30%;
}

#donation_judge_ziggy {
  right: -30%;
  bottom: 20%;
  width: 24%;
}

@media screen and (max-width: 520px) {  
  #writing_ziggy {
    top: -9%;
  }

  #stars {
    top: 50%;
  }
}

@media screen and (max-width: 425px) {  
  #pointing_ziggy {
    left: -14%;
    top: 18%;
    width: 22%;
  }
  
  #donation_ziggy {
    right: -46%;
    top: -21%;
    width: 45%;
  }
  
  #donation_thinking_ziggy {
    left: -33%;
    top: -3%;
    width: 30%;
  }
  
  #donation_box_holding_ziggy {
    right: -38%;
    top: -3%;
    width: 38%;
  }
  
  #no_coin {
    left: -43%;
    top: 30%;
    width: 38%;
  }
  
  #coin_looking_ziggy {
    right: -50%;
    top: 60%;
    width: 60%;
  }
  
  #safeguarding_ziggy {
    left: -50%;
    top: -3%;
    width: 52%;
  }
  
  #safeguarding_footprints {
    right: -56%;
    top: 60%;
    width: 52%;
  }
  
  #gift_ziggy {
    left: -47%;
    top: -15%;
    width: 88%;
  }
  
  #staircase_ziggy {
    right: -49%;
    top: -33%;
    width: 67%;
  }
  
  #person_lock {
    right: -45%;
    bottom: 85%;
    width: 46%;
  }
  
  #donation_data_safe_ziggy {
    left: -46%;
    bottom: 74%;
    width: 45%;
  }
  
  #calender_ziggy {
    right: -50%;
    bottom: 52%;
    width: 50%;
  }
  
  #coin_slotting_ziggy {
    left: -50%;
    bottom: 15%;
    width: 48%;
  }
  
  #donation_judge_ziggy {
    right: -47%;
    bottom: 45%;
    width: 45%;
  }
}

@media screen and (max-width: 330px) {  
  #writing_ziggy {
    top: -5%;
  }

  #stars {
    top: 51%;
  }

  #complaint_ziggy {
    bottom: 94%;
  }
}