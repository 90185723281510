.schedule-demo-title {
  font-size: 1.6em;
  color: #a5fffb;
  text-align: center;
  margin-bottom: 10px;
}

@media screen and (max-width: 500px) {
  .schedule-demo-title {
    font-size: 1.2em;
  }
}

.schedule-demo-container {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  z-index: 0;
  background-image: url('/public/Images/scheduleDemo/schedule_demo_ziggys_on_earth.png'),
    url('/public/Images/scheduleDemo/schedule_demo_galaxy_rocket.png');
  background-repeat: no-repeat, no-repeat;
  background-position: top 100% left, top -10% right;
  background-size: 32%, 26%;
}

@media screen and (max-width: 1120px) {
  .schedule-demo-container {
    background-position: top -1% left, top -1% right;
  }
}

.schedule-demo-form-container {
  width: 90%;
  background: transparent;
  background-image: url("/public/Images/scheduleDemo/schedule_demo_form_bg.png");
  background-repeat: no-repeat;
  background-size: 106% 103%;
  background-position: center;
  border-radius: 40px;
  padding: 6% 12%;
  z-index: 1;
}

@media screen and (max-width: 1120px) {
  .schedule-demo-form-container {
    width: 150%;
    padding: 20% 12%;
    margin: 0;
  }
}

@media screen and (max-width: 600px) {
  .schedule-demo-form-container {
    width: 200%;
  }
}

.schedule-demo-short-form-input {
  background: transparent;
  background-image: url("/public/Images/scheduleDemo/schedule_demo_short_input_field.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  color: #a5fffb !important;
  border: 0;
  z-index: 1;
  padding: 0.700rem 0.75rem;
}

@media screen and (max-width: 767px) {
  .schedule-demo-short-form-input {
    background-image: url("/public/Images/scheduleDemo/schedule_demo_long_input_field.png");
  }
}

.schedule-demo-long-form-input {
  background: transparent;
  background-image: url("/public/Images/scheduleDemo/schedule_demo_long_input_field.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  color: #a5fffb !important;
  border: 0;
  z-index: 1;
  padding: 0.700rem 0.75rem;
}

.schedule-demo-form-dropdown {
  background-color: transparent;
  background-image: url("/public/Images/scheduleDemo/schedule_demo_dropdown_field.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  color: #a5fffb !important;
  border: 0;
  z-index: 1;
  padding: 0.700rem 0.75rem;
}

select.schedule-demo-form-dropdown option {
  background: #0e1655;
}

.schedule-demo-short-form-input:focus, .schedule-demo-long-form-input:focus, .schedule-demo-form-dropdown:focus {
  background-color: transparent;
  outline: none !important;
  box-shadow: none;
}

.schedule-demo-error-msg {
  color: #a5fffb;
  font-size: 12px;
}

#tablet_ziggy {
  width: 24%;
  right: 10%;
  top: 30%;
  z-index: 1;
}

@media screen and (max-width: 1120px) {
  #tablet_ziggy {
    width: 20%;
    right: 1%;
    top: 34%;
  }
}

@media screen and (max-width: 767px) {
  #tablet_ziggy {
    top: 27%;
  }
}

@media screen and (max-width: 600px) {
  #tablet_ziggy {
    display: none;
  }
}

.firstAndLastNames {
  display: inherit;
  flex-direction: row;
}

@media only screen and (max-width: 500px) {
  .firstAndLastNames {
    display: flex;
    flex-direction: column;
  }
}

.availabilityText {
  color: rgb(181, 71, 181);
  margin: 10px 0;
}

.formLabel {
  color: #a5fffb;
  margin-right: 10px;
}

.formInput {
  width: 150px;
}

.button1 {
  background: #8938e8;
  mix-blend-mode: normal;
  box-shadow: inset -7px 43px 64px -15px rgba(76, 216, 222, 0.95);
  margin-bottom: 400px;
  margin-top: 20px;
}

.submit-button {
  width: 30%;
  background: transparent;
  border: 0;
  padding: 0;
  margin-top: 10px;
}

.submit-button:hover {
  background: transparent;
}

.submit-button:focus {
  border: 0;
  outline: none;
  background: transparent;
}

.error {
  color: #fc8181;
  font-size: 0.75rem;
  text-align: left;
  margin-top: 0.25rem;
}

.row-cols-lg-2 > .col {
  width: 70%;
}

/* Feedback page styles */

.schedule-feed-inner-container {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  z-index: 0;
  background-image: url('/public/Images/learnMore/learn_more_ziggys_on_earth.png'),
    url('/public/Images/learnMore/learn_more_galaxy_rocket.png');
  background-repeat: no-repeat, no-repeat;
  background-position: top 50% left, top 55% right;
  background-size: 29%, 20%;
}

.schedule-feedback-img {
  width: 35%;
  margin-top: 5%;
  margin-bottom: 8%;
}

@media (max-width: 990px) {
  .schedule-feedback-img {
    width: 70%;
  }
}