@import url(./GlobalStyles.css);
.form-background {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  -webkit-box-shadow: 0px 0px 14px 6px var(--form-label-color);
  box-shadow: 0px 0px 14px 6px var(--form-label-color);
  border-radius: 40px;
}

.form-body {
  width: 90%;
  background: transparent;
  background-image: url("/public/Images/form/form_bg.png");
  background-repeat: no-repeat;
  background-size: 107% 106%;
  background-position: center;
  border-radius: 40px;
  padding: 6% 12%;
  z-index: 1;
}

.form-input {
  -webkit-box-shadow: 0px 0px 8px 4px var(--form-label-color);
  box-shadow: 0px 0px 8px 4px var(--form-label-color);
  background-color: transparent;
  background-image: url("/public/Images/form/input_field.png");
  background-repeat: no-repeat;
  background-size: 104% 100%;
  background-position: center;
  border: 0;
}

.form-input:focus {
  background-color: transparent;
  outline: none !important;
  box-shadow: none;
}

.form-label {
  color: var(--form-label-color);
  margin-bottom: 0.2rem;
}

.form-button-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.form-button {
  width: fit-content;
  padding: 0.5rem 3rem;
  border-radius: 40px;
  background: rgb(2, 0, 36);
  background: linear-gradient(
    0deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(185, 105, 255, 1) 0%,
    rgba(3, 204, 250, 1) 99%,
    rgba(0, 212, 255, 1) 100%
  );
}

select.form-input option {
  background: #0e1655;
}

input[name='phoneNumber'] {
  width: 100%;
}