.media-inner-container {
  width: 100%;
  height: auto;
  position: relative;
  font-size: 20px;
  padding-bottom: 2%;
  background-image: url("/public/Images/home/galaxy.png");
  background-repeat: no-repeat;
  background-position: top right;
  background-size: 50%;
}

.headline {
  width: 70%;
  margin: 0 0 -4% 6%;
  z-index: 1;
}

.screen {
  width: 100%;
  margin-bottom: 100px;
  position: relative;
}

#screen-seven {
  margin-bottom: 0;
}

.screen-back1 {
  width: 100%;
  margin: 0 auto;
  padding-bottom: 23%;
  background-image: url("/public/Images/mediaCentre/screen_one.png");
  background-size: 100%;
  align-content: center;
  background-repeat: no-repeat;
}

#screen_one {
  width: 47.9%;
  margin-left: 25.3%;
  margin-top: 5%;
  margin-bottom: 5%;
}

.react-player_one {
  display: block;
  padding-top: 42.2%;
}

.screen-back2 {
  width: 100%;
  margin: 0 auto;
  padding-bottom: 8%;
  background-image: url("/public/Images/mediaCentre/screen_two.png");
  background-size: 100%;
  align-content: center;
  background-repeat: no-repeat;
}

#screen_two {
  width: 47.2%;
  margin-left: 24.7%;
  margin-bottom: 10%;
}

.react-player_two {
  display: block;
  padding-top: 22.2%;
}

.screen-back3 {
  width: 100%;
  margin: 0 auto;
  padding-bottom: 10%;
  background-image: url("/public/Images/mediaCentre/screen_three.png");
  background-size: 100%;
  align-content: center;
  background-repeat: no-repeat;
}

#screen_three {
  width: 46.5%;
  margin-left: 24.5%;
  padding-top: 20.9%;
  margin-bottom: 11%;
}

.react-player_three {
  display: block;
  padding-top: 0;
}

.screen-back4 {
  width: 100%;
  margin: 0 auto;
  padding-bottom: 4%;
  background-image: url("/public/Images/mediaCentre/screen_four.png");
  background-size: 100%;
  align-content: center;
  background-repeat: no-repeat;
}

#screen_four {
  width: 46.4%;
  margin-left: 24.2%;
  padding-top: 25.5%;
  margin-bottom: 11%;
}

.react-player_four {
  display: block;
  padding-top: 1%;
}

.screen-back5 {
  width: 100%;
  margin: 0 auto;
  padding-bottom: 13%;
  background-image: url("/public/Images/mediaCentre/screen_five.png");
  background-size: 100%;
  align-content: center;
  background-repeat: no-repeat;
}

#screen_five {
  width: 46.2%;
  margin-left: 24.5%;
  padding-top: 6.8%;
  margin-bottom: 10%;
}

.react-player_five {
  display: block;
  padding-top: 0;
}

.screen-back6 {
  width: 95%;
  margin: 0 auto;
  padding-bottom: 4%;
  background-image: url("/public/Images/mediaCentre/screen_six.png");
  background-size: 100%;
  align-content: center;
  background-repeat: no-repeat;
}

#screen_six {
  width: 47.6%;
  margin-left: 22.3%;
  padding-top: 22%;
  display: inline-block;
  height: 100%;
  margin-bottom: 12%;
}

.react-player_six {
  display: block; 
  padding-top: 16.4%;
}

.screen-back7 {
  width: 100%;
  margin: 0 auto;
  padding-bottom: 0;
  background-image: url("/public/Images/mediaCentre/screen_seven.png");
  background-size: 100%;
  align-content: center;
  background-repeat: no-repeat;
}

#screen_seven {
  width: 46.7%;
  margin-left: 23.8%;
  padding-top: 1%;
  display: inline-block;
  height: 100%;
  margin-bottom: 25%;
}

.react-player_seven {
  display: block; 
  padding-top: 34.8%;
}

@media screen and (max-width: 1400px) {
  .media-inner-container {
    background-image: url("/public/Images/home/galaxy.png");
    background-position: top right;
  }
}

@media screen and (max-width: 651px) {
  .media-inner-container {
    background-image: url("/public/Images/home/galaxy.png");
    background-position: top right;
  }
}

@media screen and (max-width: 425px) {
  .screen {
    margin-bottom: 30px;
  }
}