.item {
  margin: 15px 10px;
  padding: 15px;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px #3DC0C8, inset 0px 0px 12px 2px #44E5DD;
  border-radius: 8px; 
}

.icon {
  display: inline;
  margin-top: 50px;
}

.title {
  display: inline-block;
  border-right: 2px solid #3DC0C8;
}

.description {
  font-size: 10px;
}

.price {
  display: inline-block;
  border-right: 2px solid #3DC0C8;
}

.quantity {
  display: inline;
}

.delete-button {
  border-radius: 50%;
  background-color: transparent;
  border: 1px solid white;
}